<template>
  <v-container class="pa-0 d-flex flex-column reg-container mt-4 mt-sm-11">
    <transition :name="this.$route.meta.disableTransition ? '' : routeTransition" mode="out-in">
      <router-view :key="$route.path"></router-view>
    </transition>
    <register-footer v-if="this.$route.meta.footer"></register-footer>
    <rocket-chat v-if="this.$route.meta.isChatEnabled"></rocket-chat>
    <pharmacy-referral
      @hide="hide"
      v-if="pharmacy && revealed"
      :pharmacyId="pharmacy"
    ></pharmacy-referral>
  </v-container>
</template>
<script>
export default {
  name: 'Registerv1',
  components: {
    RegisterFooter: () => import('../components/RegisterFooter.vue'),
    RocketChat: () => import('../components/RocketChat.vue'),
    PharmacyReferral: () => import('../components/PharmacyReferral.vue'),
  },
  data: () => ({
    routeTransition: 'component-slide',
    revealed: true,
  }),
  computed: {
    pharmacy() {
      return this.$store.state.registration.pharmacyReferralId;
    },
  },
  methods: {
    async setTenantParams() {
      const {
        tenantUrl = null,
        pharmacyId = null,
        theme = null,
        employeeRef = null,
        flow = null,
        onDemand = false,
      } = localStorage;
      await this.$store.dispatch('registerInit', {
        tenantUrl,
        flow,
        theme,
        pharmacyId,
        employeeRef,
        onDemand: Boolean(onDemand),
      });
      this.$router.push({ name: this.$store.getters.nextFlowRoute('RegisterGateway') });
    },
    hide() {
      this.revealed = false;
    },
  },
  async mounted() {
    if (this.$route.name !== 'RegisterGateway') {
      await this.setTenantParams();
    }
  },
  watch: {
    async $route(to, from) {
      const toIndex = this.$store.getters.getFlowRouteIndex(to.name);
      const fromIndex = this.$store.getters.getFlowRouteIndex(from.name);
      if (toIndex - fromIndex > 0) {
        this.routeTransition = 'component-slide';
      } else {
        this.routeTransition = 'component-slide-reverse';
      }
    },
  },
};
</script>
